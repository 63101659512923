<div class="card text-white bg-{{ bgClass }}">
    <div class="card-header">
        <div class="row">
            <div class="col col-xs-3">
                <i class="fa {{ icon }} fa-5x"></i>
            </div>
            <div class="col col-xs-9 text-right">
                <div class="d-block huge">{{ count }}</div>
                <div class="d-block">{{ label }}</div>
            </div>
        </div>
    </div>
    <div class="card-footer">
        <span class="float-left">View Details {{ data }}</span>
        <a class="float-right card-inverse" href="javascript:void(0)">
            <span><i class="fa fa-arrow-circle-right"></i></span>
        </a>
    </div>
</div>
