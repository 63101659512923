import { Component, Inject, OnInit } from '@angular/core';
import { MsalInteractionService } from './services/msal-interaction.service';
import { AuthenticationResult, EventMessage, EventType, IdTokenClaims, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { Subject, Subscription, filter, takeUntil } from 'rxjs';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'reliability.ui';
  isIframe = false;
  protected subscriptions = new Array<Subscription>();
  public userTokenClaimsList: string;
  public userTokenClaims: IdTokenClaims;
  public SSOStatusMessage: string;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration, 
                  private authService: MsalService,
                  private msalBroadcastService: MsalBroadcastService, 
                  private _msalInteractionService: MsalInteractionService) {
  }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
    //console.log('1.(app.component) - Getting MSAL Token: call MsalInteractionService.handleMSALToken();')
    this._msalInteractionService.handleMSALToken();
  }


  // unsubscribe to events when component is destroyed
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
