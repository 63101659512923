import { Injectable, OnDestroy } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, IdTokenClaims } from '@azure/msal-browser';
import { AuthenticationResult } from '@azure/msal-browser/dist/response/AuthenticationResult';
import { BehaviorSubject, Observable, Subject, filter, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class MsalInteractionService implements OnDestroy {

  private readonly _destroying$ = new Subject<void>(); 
  private _userTokenClaims: IdTokenClaims = null; 
  private _userTokenClaims$ = new BehaviorSubject<IdTokenClaims>(this._userTokenClaims); 

  private _SSOStatus = 'Checking With Azure...'; 
  private _SSOStatus$ = new BehaviorSubject<string>(this._SSOStatus); 

  //---Output variables for testing---------------------------------
  private msalMessage: EventMessage; //JCG 7/25/24
  //----------------------------------------------------------------

  constructor(private authService: MsalService, 
              private msalBroadcastService: MsalBroadcastService)  { }

  /*************************************************************************
   *                         handleMSALToken()
   *************************************************************************/
  handleMSALToken(): void {
    try {

      //LISTEN FOR TOKEN SUCCESS-----------------------------------------
      this.msalBroadcastService.msalSubject$
        .pipe(
          filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
          takeUntil(this._destroying$)
        )
        //Subscribe------------------------------------------
        .subscribe((result: EventMessage) => {
          const payload = result.payload as AuthenticationResult;
          this.authService.instance.setActiveAccount(payload.account);
          //console.log('MSAL Event Type: ', result.eventType); //JCG 7/26/24

          //+++++++++++++++++++++++++++++++++++++++++++++++++TOKEN ACQUIRED
          if (result.eventType === EventType.LOGIN_SUCCESS || result.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
            this.msalMessage = result; //JCG 7/25/24

            this.updateSSOStatus('Authentication Succesful');
            this.updateUserTokenClaims(this.authService.instance.getActiveAccount()?.idTokenClaims) 

            let accountInfo = result.payload['account'];
            accountInfo.isLoggedIn = true;
            accountInfo.userAccessToken = result.payload['accessToken'];
            accountInfo.idToken = result.payload['idToken'];
            //Adding Account Info to Session storage. 
            typeof sessionStorage.setItem("CCB::ACTIVE_ACCOUNT", JSON.stringify(accountInfo));

          }
        });

      //LISTEN FOR TOKEN FAILURE-------------------------------------------------------------------
      this.msalBroadcastService.msalSubject$
        .pipe(
          filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE || 
                                        msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
          takeUntil(this._destroying$)
        )
        .subscribe((result: EventMessage) => {
          this.updateSSOStatus(`There Was An Error With Your Config: ${result}`)
        });  

    } catch (error) {
      this.updateSSOStatus(`There Was An Error With Your Config: ${error}`)
    }
  }//end handleMSALToken


  get userTokenClaims(): Observable<IdTokenClaims> {
    return this._userTokenClaims$.asObservable();
  }

  get SSOStatus(): Observable<string> {
    return this._SSOStatus$.asObservable();
  }

  private updateUserTokenClaims(claims: IdTokenClaims): void {
    this._userTokenClaims$.next(claims);
  }

  private updateSSOStatus(status: string): void {
    this._SSOStatus$.next(status);
  }

 //------------------------JCG 7/24/24---Outputs for testing------------------
 get loginResults() :EventMessage {
  return this.msalMessage;
}

 get tokenExpiresOn() {
  let payload = this.msalMessage.payload;
  return payload['expiresOn'];
  }

get accessToken(){
  let payload = this.msalMessage.payload;
  return payload['accessToken'];
}

get isTokenExpired(){
  let payload = this.msalMessage.payload;
  let expireDttm = payload['expiresOn'];
  let dt = new Date();
  if (expireDttm > dt){
    return false;
  }
  return true;
}

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

}//end class
