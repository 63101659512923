
export const environments = {
    local: {
        production: false,
        envName: 'local',
        apiBaseUrl: 'http://localhost:4200/',
        //azureClientId: '94985a11-04bc-41ef-9a40-48e9899d7d02', //test application.  Replace with your App ID
        azureClientId: '4289e761-59df-4360-807c-b7a83b6786f0', //CC-Reliability-Dev CID
        unauthorizedRoute: "/unauthorized"
    },
    sbx: {
        production: false,
        envName: 'sbx',
        apiBaseUrl: 'https://emp-dev-api.duke-energy.com/your-proxy-name/',
        azureClientId: '@AZURE-CLIENT-ID@', //replace values script will replace from vault
        unauthorizedRoute: "/unauthorized"
    },
    dev: {
        production: false,
        envName: 'dev',
        //apiBaseUrl: 'https://emp-dev-api.duke-energy.com/your-proxy-name/',
        apiBaseUrl: 'https://graph.microsoft.com/v2.0/me', // QAJ 8/29/24 Updated apiBaseUrl
        azureClientId: '4289e761-59df-4360-807c-b7a83b6786f0', //replace values script will replace from vault
        unauthorizedRoute: "/unauthorized"
    },
    tst: {
        production: false,
        envName: 'test',
        apiBaseUrl: 'https://emp-tst-api.duke-energy.com/your-proxy-name/',
        azureClientId: '@AZURE-CLIENT-ID@',
        unauthorizedRoute: "/unauthorized"
    },
    qa: {
        production: false,
        envName: 'qa',
        apiBaseUrl: 'https://emp-qa-api.duke-energy.com/your-proxy-name/',
        //apiBaseUrl: 'https://customer-systems-cntl-center-qa.duke-energy.app/',
        azureClientId: '060cdbe9-e3cb-446e-9905-834750e784bc',
        unauthorizedRoute: "/unauthorized"
    },
    prod: {
        production: true,
        envName: 'prod',
        apiBaseUrl: 'https://emp-api.duke-energy.com/your-proxy-name/',
        //apiBaseUrl: 'https://customer-systems-cntl-center-prod.duke-energy.app/',
        azureClientId: 'aa1b25f8-c99c-4621-864b-30d0ef885b73',
        unauthorizedRoute: "/unauthorized"
    }
};