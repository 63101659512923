import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router, private authenticateService: AuthenticationService) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        try {
            if (this.authenticateService.isAuthenticated()) {
                return true;
            }
            else {
                this.router.navigate(['/noaccess'], { state: { message: 'No access, please contact Administrator' } })
            }
        } catch (e) {
            this.router.navigate(['/noaccess'], { state: { message: 'No access, please contact Administrator, Error :' + e.message } })
        }
        return false;
    }
}