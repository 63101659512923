import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';

/******************************************************************
 * The lazy-loading syntax uses loadChildren followed by a function 
 * that uses the browser's built-in import('...') syntax for dynamic 
 * imports. The import path is the relative path to the module.
 ******************************************************************/
const routes: Routes = [
  {
    //This is the default path.
    path: '',
    loadChildren: () => import('./layout/layout.module').then((m) => m.LayoutModule),
    canActivate: [MsalGuard]
  },
  { path: 'login', loadChildren: () => import('./login/login.module').then((m) => m.LoginModule) },
  { path: 'signup', loadChildren: () => import('./signup/signup.module').then((m) => m.SignupModule) },
  {
    path: 'error',
    loadChildren: () => import('./server-error/server-error.module').then((m) => m.ServerErrorModule)
  },
  {
    path: 'noaccess',
    loadChildren: () => import('./access-denied/access-denied.module').then((m) => m.AccessDeniedModule)
  },
  { path: 'not-found', loadChildren: () => import('./layout/layout.module').then((m) => m.LayoutModule) },
  { path: '**', redirectTo: 'events', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' // Set to enabledBlocking to use Angular Universal
    })
  ],
  exports: [RouterModule]
})

// @NgModule({
//     imports: [RouterModule.forRoot(routes, {})],
//     exports: [RouterModule]
// })
export class AppRoutingModule { }

export function isInIframe() {
  return window !== window.parent && !window.opener;
}
