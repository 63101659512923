import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, of } from 'rxjs';
import { AzureUserModel } from '../models/user.model';
import { BrowserCacheLocation, ILoggerCallback, IPublicClientApplication, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { environments } from 'src/config/env.config';
import { env } from 'src/config/env';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  //User Claims
  public userClaims: BehaviorSubject<AzureUserModel> = new BehaviorSubject<AzureUserModel>({});

  constructor() {
    //console.log(`Your environment is set to ${this.config.getConfig('envName')}`);
  }

  //This does not appear to be used.
  fetchUserInfo(): Observable<AzureUserModel> {
    var account = this.getUserAccount();
    if (account != null && account != undefined) {
      this.userClaims.next(account);
    }
    return of(account);
  }

  //Used Internally (below)
  getUserAccount(): any {
    var userInfo = typeof sessionStorage.getItem('CCB::ACTIVE_ACCOUNT') == 'undefined' ? null : sessionStorage.getItem('CCB::ACTIVE_ACCOUNT');
    return JSON.parse(userInfo);
  }


  //boolean (true/false)
  public isAuthenticated(): boolean {
    console.log(this.getUserAccount())
    return this.getUserAccount().isLoggedIn;
  }


  public getUserName(): string {
    return this.getUserAccount().name;
  }

  public getUserEmail(): string {
    return this.getUserAccount().mail;
  } 

  //This is coming from sessionStorage, should we check for expired token here?
  public getUserToken(): string{
    //return this.getUserAccount()?.idToken;
    return this.getUserAccount()?.accessToken; //QAJ 8/30/24 changed to use accessToken
  }
}// end AuthenticationService

//Duke Energy ID (not environment specific).
export const dukeEnergyAzureADTenantId = '2ede383a-7e1f-4357-a846-85886b2c0c4d';

/******************************************************************************************
 *  Instance Factory Provider to Define our IPublicClientApplication
 *****************************************************************************************/
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environments[env.env].azureClientId,
      authority: 'https://login.microsoftonline.com/' + dukeEnergyAzureADTenantId,
      //authority: 'https://sts.windows.net/' + dukeEnergyAzureADTenantId, //QAJ 8/29/24 changed the azure domain
      redirectUri: '/', //Go back to page you started at
      postLogoutRedirectUri: environments[env.env].unauthorizedRoute
    },
    cache: {
      cacheLocation: BrowserCacheLocation.MemoryStorage,
      storeAuthStateInCookie: true 
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Error, //JCG 9/11/24
        //logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}//end MSALInstanceFactory()

export const loggerCallback: ILoggerCallback = (level: number, message: string) => {
  console.log(`From MSAL: ${message}`);
};

/******************************************************************************************
 * Factory Provider for MSAL Interceptor Configuration
 *****************************************************************************************/
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  
  const protectedResourceMap = new Map<string, Array<string>>(); 
  
  //protectedResourceMap.set(environments[env.env].apiBaseUrl, ['User.Read.All']);
  //protectedResourceMap.set(environments[env.env].apiBaseUrl, ['User.Read']);
  protectedResourceMap.set(environments[env.env].apiBaseUrl, ['App.ReadWrite.All', 'AzureCLI_Auth', 'User.Read.All', 'User.Read']); //QAJ 8/30/24 Changed api permissions
  //QAJ 8/30/24 Changed api permissions
  protectedResourceMap.set('https://wl3gzj6cac.execute-api.us-east-1.amazonaws.com/dev', ['api://4289e761-59df-4360-807c-b7a83b6786f0/AzureCLI_Auth', 'api://4289e761-59df-4360-807c-b7a83b6786f0/App.ReadWrite.All']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}//end MSALInterceptorConfigFactory()
  
/******************************************************************************************
 * Factory Provider for MSAL Guard Configuration.
 * JCG 11/20/23 changed scope from User.Read.All to User.Read. This appears to be the only 
 * scope that will open the app.
 *****************************************************************************************/
  export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: ['User.Read']
        //scopes:['App.ReadWrite.All']
      },
      loginFailedRoute: environments[env.env].unauthorized
    };
  }