import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthenticationService) {}

  /******************************************************************
   * Swaps out the API Request header access token with the ID
   * token. 
   ******************************************************************/
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    request = request.clone({
      setHeaders: {
        'Content-Type': 'application/json',
        'Accept'       : 'application/json',
        'Authorization' : `Bearer ${this.authService.getUserToken()}`, //ID Token from session storage.

      },
    });
    return next.handle(request);
  }//end intercept

}//end AuthInterceptor